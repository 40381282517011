import React from 'react';
import '../css/ChatWindow.css';

const ChatWindow = ({ messages }) => {
    return (
        <div className="chat-window" id="chatWindow">
            {messages.map((msg, index) => (
                <div key={index} className={msg.sender === 'You' ? 'user-message' : 'bot-message'}>
                    <div className="message-bubble">
                        <strong>{msg.sender === 'You' ? 'User' : 'Bot'}:</strong> {msg.message}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ChatWindow;
