// import './App.css';
// import React from 'react';

import { BrowserRouter as Router, Route, Routes, Link, useNavigate, Navigate } from 'react-router-dom'
// import Home from './views/Home';
// // import About from './pages/About';
// // import Contact from './pages/Contact';
import Page1 from './views/Page1'; // Import your custom pages
// import Page2 from './views/Page2';

import React, {useState, useEffect} from 'react';
// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Header from './views/Header';
import Footer from './views/Footer';
import Home2 from './views/HomePage';
import './css/base.css';
import './css/main.css';
import ArchivesPage from './views/ArchivesPage';
import StyleGuidePage from './views/StyleGuidePage';
import HomePage from './views/HomePage';
import ChatPage from './views/ChatPage';
import BlogSingle from './views/BlogSingle';
import AdminPanel from './views/AdminPanel';
import Login from './views/Login';
import PrivateRoute from './views/PrivateRoute';
import axios from 'axios';
import constants from './chatConstants';

function App() {
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setAuthenticated(true);
    }
  }, []);

  useEffect(() => {
    const verifyToken = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          await axios.post(`${constants.baseURL}/verify-token`, {}, {
            headers: { Authorization: token },
          });
          setAuthenticated(true);
        } catch (err) {
          // Token is invalid or expired
          localStorage.removeItem('token');
          setAuthenticated(false);
        }
      }
    };
    
    verifyToken();
  }, []);

  const [posts, setPosts] = useState([
    {
      title: "Welcome to my blog!",
      author: "John Doe",
      tags: ["welcome", "introduction"],
      content: "This is the content of the first post. Welcome to my blog! I hope you find it interesting."
    },
    {
      title: "This is my second post.",
      author: "John Doe",
      tags: ["second", "update"],
      content: "In this post, I'll talk about what I've been up to recently. Stay tuned for more updates!"
    },
    {
      title: "React is awesome!",
      author: "John Doe",
      tags: ["react", "javascript", "frontend"],
      content: "Let's dive into why React is such a popular library for building user interfaces. Here are some reasons..."
    }
  ]);
  

  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<ChatPage />} />
          <Route path="/page1" element={<Page1 />} />
          {/* <Route path="/blog" element={<HomePage />} /> */}
          <Route path="/single/:id" element={<BlogSingle />} />
          <Route path="/single" element={<BlogSingle />} />
          <Route path="/archives" element={<ArchivesPage />} />
          <Route path="/demo" element={<StyleGuidePage />} />
          <Route path="/about" element={<ChatPage />} />
          <Route
          path="/admin"
          element={authenticated ? <AdminPanel /> : <Navigate to="/login" />}
          />
          <Route
            path="/login"
            element={authenticated ? <Navigate to="/admin" /> : <Login onLoginSuccess={() => setAuthenticated(true)} />}
          />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}


export default App;

