import React from 'react';

const Footer = () => {
  return (
    <footer className="s-footer">
      <div className="row s-footer__top">
        {/* <div className="column">
          <ul className="s-footer__social">
            <li><a href="#0"><i className="fab fa-facebook-f" aria-hidden="true"></i></a></li>
            <li><a href="#0"><i className="fab fa-twitter" aria-hidden="true"></i></a></li>
            <li><a href="#0"><i className="fab fa-youtube" aria-hidden="true"></i></a></li>
            <li><a href="#0"><i className="fab fa-vimeo-v" aria-hidden="true"></i></a></li>
            <li><a href="#0"><i className="fab fa-instagram" aria-hidden="true"></i></a></li>
            <li><a href="#0"><i className="fab fa-linkedin" aria-hidden="true"></i></a></li>
            <li><a href="#0"><i className="fab fa-skype" aria-hidden="true"></i></a></li>
          </ul>
        </div> */}
      </div>
      <div className="row s-footer__bottom">
        <div className="large-6 tab-full column s-footer__info">
          <h3 className="h6">About This Website</h3>
          <p>
            This website is a simple blog created using the Keep It Simple template by StyleShout. It is a react app that uses AWS services for deployment. If you have any questions or feedback, feel free to reach out to me by email at 
            <a href="mailto:admin@sihan.ca"> admin@sihan.ca</a>.
          </p>
        </div>
        <div className="large-6 tab-full column">
          <div className="row">
            <div className="large-8 tab-full column">
              <h3 className="h6">Photostream</h3>
              <ul className="photostream group">
              <li><a href="#0"><img alt="thumbnail" src="/images/thumb.jpg" /></a></li>
              <li><a href="#0"><img alt="thumbnail" src="/images/thumb.jpg" /></a></li>
                <li><a href="#0"><img alt="thumbnail" src="/images/thumb.jpg" /></a></li>
                <li><a href="#0"><img alt="thumbnail" src="/images/thumb.jpg" /></a></li>
                <li><a href="#0"><img alt="thumbnail" src="/images/thumb.jpg" /></a></li>
                <li><a href="#0"><img alt="thumbnail" src="/images/thumb.jpg" /></a></li>
                <li><a href="#0"><img alt="thumbnail" src="/images/thumb.jpg" /></a></li>
                <li><a href="#0"><img alt="thumbnail" src="/images/thumb.jpg" /></a></li>
              </ul>
            </div>
            <div className="large-4 tab-full column">
              <h3 className="h6">Navigate</h3>
              <ul className="s-footer__list s-footer-list--nav group">
                <li><a href="/">Home</a></li>
                {/* <li><a href="#0">Blog</a></li>
                <li><a href="#0">Demo</a></li>
                <li><a href="#0">Archives</a></li> */}
                <li><a href="/about">About Me</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="ss-copyright">
          <span>© Copyright Keep It Simple 2019</span> 
          <span>Design by <a href="https://www.styleshout.com/">StyleShout</a></span>
        </div>
      </div>
      <div className="ss-go-top">
        <a className="smoothscroll" title="Back to Top" href="#top">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path d="M12 0l8 9h-6v15h-4v-15h-6z" />
          </svg>
        </a>
      </div>
    </footer>
  );
};

export default Footer;
