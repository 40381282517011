import React from 'react';
import Header from './Header';
import Footer from './Footer';
import Sidebar from './Sidebar';

const ArchivesPage = () => {
    return (
        <div id="top">
            <div className="s-content">
                <div className="row">
                    <div id="main" className="s-content__main large-8 column">
                        <section className="page-content">
                            <h2 className="page-content__title h1">Archives.</h2>
                            <p className="lead">
                                Lorem ipsum Nisi enim est proident est magna occaecat dolore 
                                proident eu ex sunt consectetur consectetur dolore enim nisi exercitation 
                                adipisicing magna culpa commodo deserunt ut do Ut occaecat. Lorem ipsum Veniam 
                                consequat quis.
                            </p>
                            <h4>Last 10 Posts.</h4>
                            <ul>
                                <li><a href="#0">Dolor irure velit commodo cillum sit nulla</a></li>
                                <li><a href="#0">laborum mollit quis nostrud sed</a></li>
                                <li><a href="#0">consequat occaecat fugiat in adipisicing</a></li>
                                <li><a href="#0">qui labore cillum sit in tempor veniam consequat</a></li>
                                <li><a href="#0">quis nostrud sed sed</a></li>
                                <li><a href="#0">quis proident ullamco ut dolore</a></li>
                                <li><a href="#0">Dolor irure velit commodo cillum sit nulla</a></li>
                                <li><a href="#0">veniam dolor dolor irure velit commodo cillum</a></li>
                                <li><a href="#0">Dolor irure velit commodo cillum sit nulla</a></li>
                                <li><a href="#0">voluptate enim veniam consequat occaecat</a></li>
                            </ul>

                            <h4>Archives By Month.</h4>
                            <ul>
                                <li><a href="#0">July 2019</a></li>
                                <li><a href="#0">August 2019</a></li>
                                <li><a href="#0">September 2019</a></li>
                                <li><a href="#0">October 2019</a></li>
                                <li><a href="#0">November 2019</a></li>
                                <li><a href="#0">December 2019</a></li>
                            </ul>

                            <h4>Archives By Category.</h4>
                            <ul>
                                <li><a href="#0">Wordpress</a></li>
                                <li><a href="#0">Ghost</a></li>
                                <li><a href="#0">Joomla</a></li>
                                <li><a href="#0">Drupal</a></li>
                                <li><a href="#0">Magento</a></li>
                                <li><a href="#0">Uncategorized</a></li>
                            </ul>

                            <h4>Site Map.</h4>
                            <ul>
                                <li><a href="#0">Archives</a></li>
                                <li><a href="#0">Home</a></li>
                                <li><a href="#0">About Us</a></li>
                                <li><a href="#0">Blog</a></li>
                                <li><a href="#0">Demo</a></li>
                                <li><a href="#0">Other Stuff</a></li>
                            </ul>
                        </section>
                    </div>
                    <Sidebar />
                </div>
            </div>
        </div>
    );
}

export default ArchivesPage;
