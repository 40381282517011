import React from 'react';
import Header from './Header';
import Footer from './Footer';
import StyleGuideContent from './StyleGuideContent';

const StyleGuidePage = () => {
    return (
        <div id="top">
            <div className="s-content">
                <StyleGuideContent />
            </div>
        </div>
    );
}

export default StyleGuidePage;
