import React, { useEffect, useState } from 'react';
import axios from 'axios';
import constants from '../chatConstants';

const AdminPanel = () => {
  const [currentPosts, setCurrentPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newPost, setNewPost] = useState('');
  const [newTitle, setNewTitle] = useState('');
  const [newAuthor, setNewAuthor] = useState('');
  const [newDescription, setNewDescription] = useState('');
  const [newTags, setNewTags] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [editPostId, setEditPostId] = useState(null);
  const [totalPages, setTotalPages] = useState(1);

  const postsPerPage = 4; // Adjust the number of posts per page as needed

  const fetchPosts = async (page = 1) => {
    try {
      setLoading(true); // Set loading to true before fetching data
      const response = await axios.get(`${constants.baseURL}/blog`, {
        params: {
          page: page,
          limit: postsPerPage,
        },
      });

      setCurrentPosts(response.data.posts);
      const totalPosts = response.data.totalPosts;
      setTotalPages(Math.ceil(totalPosts / postsPerPage));
    } catch (error) {
      console.error('Error fetching posts:', error);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };

  useEffect(() => {
    fetchPosts(currentPage);
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Function to add a new post
  const handleAddPost = async () => {
    const newPostObject = {
      title: newTitle,
      author: newAuthor,
      description: newDescription,
      tags: newTags.split(',').map(tag => tag.trim()),
      content: newPost,
    };

    try {
      await axios.post(`${constants.baseURL}/blog`, newPostObject);
      fetchPosts(currentPage);
    } catch (error) {
      console.error('Error adding post:', error);
    }

    // Clear input fields
    setNewTitle('');
    setNewAuthor('');
    setNewDescription('');
    setNewTags('');
    setNewPost('');
  };

  // Function to update an existing post
  const handleUpdatePost = async () => {
    const updatedPostObject = {
      title: newTitle,
      author: newAuthor,
      description: newDescription,
      tags: newTags.split(',').map(tag => tag.trim()),
      content: newPost,
    };

    try {
      if (editPostId !== null) {
        await axios.put(`${constants.baseURL}/blog/${editPostId}`, updatedPostObject);
        setEditPostId(null);
        fetchPosts(currentPage);
      }
    } catch (error) {
      console.error('Error updating post:', error);
    }

    // Clear input fields
    setNewTitle('');
    setNewAuthor('');
    setNewDescription('');
    setNewTags('');
    setNewPost('');
  };

  // Delete post by ID
  const handleDeletePost = async (postId) => {
    try {
      const deleteRes = await axios.delete(`${constants.baseURL}/blog/${postId}`);
  
      if (deleteRes.status === 200) {
        fetchPosts(currentPage); // Refetch posts after successful deletion
      } else {
        console.error('Failed to delete post. Status:', deleteRes.status);
      }
    } catch (error) {
      console.error('Error deleting post:', error);
    }
  };

  // Edit post by ID
  const handleEditPost = (postId) => {
    const postToEdit = currentPosts.find(post => post.id === postId);
    if (postToEdit) {
      setNewTitle(postToEdit.title || '');
      setNewAuthor(postToEdit.author || '');
      setNewDescription(postToEdit.description || '');
      setNewTags(Array.isArray(postToEdit.tags) ? postToEdit.tags.join(', ') : '');
      setNewPost(postToEdit.content || '');
      setEditPostId(postId);
    } else {
      console.error('Post not found');
    }
  };

  return (
    <div id="top">
      <div className="s-content">
        <div className="row">
          <div>
            <h1>Admin Panel</h1>
            <div style={{ marginBottom: '20px' }}>
              <h3>{editPostId !== null ? 'Edit Post' : 'Add New Post'}</h3>
              <input
                type="text"
                value={newTitle}
                onChange={(e) => setNewTitle(e.target.value)}
                placeholder="Enter post title"
                style={{ width: '300px', padding: '5px', marginBottom: '10px' }}
              />
              <br />
              <input
                type="text"
                value={newAuthor}
                onChange={(e) => setNewAuthor(e.target.value)}
                placeholder="Enter author name"
                style={{ width: '300px', padding: '5px', marginBottom: '10px' }}
              />
              <br />
              <input
                type="text"
                value={newTags}
                onChange={(e) => setNewTags(e.target.value)}
                placeholder="Enter tags (comma separated)"
                style={{ width: '300px', padding: '5px', marginBottom: '10px' }}
              />
              <br />
              <textarea
                value={newDescription}
                onChange={(e) => setNewDescription(e.target.value)}
                placeholder="Enter description"
                style={{ width: '300px', height: '50px', padding: '5px', marginBottom: '10px' }}
              />
              <br />
              <textarea
                value={newPost}
                onChange={(e) => setNewPost(e.target.value)}
                placeholder="Enter post content"
                style={{ width: '300px', height: '100px', padding: '5px', marginBottom: '10px' }}
              />
              <br />
              {editPostId !== null ? (
                <button onClick={handleUpdatePost}>
                  Update Post
                </button>
              ) : (
                <button onClick={handleAddPost}>
                  Add Post
                </button>
              )}
            </div>
            <div>
              <h3>Existing Posts</h3>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {currentPosts.map((post) => (
                  <div
                    key={post.id}
                    style={{
                      width: 'calc(50% - 10px)',
                      margin: '5px',
                      padding: '10px',
                      border: '1px solid #ddd',
                      borderRadius: '4px',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div>
                      <strong>{post.title}</strong> by {post.author} <br />
                      Tags: {post.tags && Array.isArray(post.tags) ? post.tags.join(', ') : 'No tags'}
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <button
                        onClick={() => handleEditPost(post.id)}
                        style={{
                          marginLeft: '10px',
                          padding: '5px 10px',
                          backgroundColor: '#ffc107',
                          color: 'white',
                          border: 'none',
                          borderRadius: '4px',
                          cursor: 'pointer',
                          marginRight: '5px',
                        }}
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => handleDeletePost(post.id)}
                        style={{
                          padding: '5px 10px',
                          backgroundColor: '#f44336',
                          color: 'white',
                          border: 'none',
                          borderRadius: '4px',
                          cursor: 'pointer',
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                ))}
              </div>
              <div className="row">
                <div className="column large-full">
                    <nav className="pgn">
                        <ul>
                            {/* the left side button */}
                            <li>
                              <a 
                                  className={`pgn__prev ${currentPage === 1 ? 'disabled' : ''}`} 
                                  href="#0" 
                                  onClick={currentPage > 1 ? () => handlePageChange(currentPage - 1) : null}
                              >
                                  Prev
                              </a>

                            </li>
                            {/* the page numbers */}
                            {[...Array(totalPages).keys()].map(number => (
                                <li key={number + 1}>
                                    {currentPage === number + 1 ? (
                                        <span className="pgn__num current">{number + 1}</span>
                                    ) : (
                                        <a 
                                            className="pgn__num" 
                                            href="#0" 
                                            onClick={() => handlePageChange(number + 1)}
                                        >
                                            {number + 1}
                                        </a>
                                    )}
                                </li>
                            ))}
                            {/* the right side button */}
                            <li>
                              <a 
                                  className={`pgn__next ${currentPage === totalPages ? 'disabled' : ''}`} 
                                  href="#0" 
                                  onClick={currentPage < totalPages ? () => handlePageChange(currentPage + 1) : null}
                              >
                                  Next
                              </a>

                            </li>
                        </ul>
                    </nav>
                </div>
            </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminPanel;
