import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import constants from '../chatConstants';

const BlogSingle = () => {
    const { id } = useParams(); // Get the current post ID from the URL
    const [blog, setBlog] = useState(null);
    const [prevPost, setPrevPost] = useState(null);
    const [nextPost, setNextPost] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchBlogAndNavigation = async () => {
            try {
                // Fetch the current blog post
                const blogResponse = await axios.get(`${constants.baseURL}/blog/${id}`);
                setBlog(blogResponse.data);

                // Fetch the navigation data (previous and next posts)
                const navResponse = await axios.get(`${constants.baseURL}/blog/${id}/navigation`);
                setPrevPost(navResponse.data.prev);
                setNextPost(navResponse.data.next);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchBlogAndNavigation();
    }, [id]); // Re-run the effect if the ID changes

    if (loading) {
        // Add loading graphic or handling here
        return;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }
    
    // Already handled by the error state?
    // if (!blog) {
    //     return <div>Blog not found</div>;
    // }

    return (
        <div id="top">
            <div className="s-content">
                <div className="row">
                    <article className="entry">
                        <header className="entry__header">
                            <h2 className="entry__title h1">{blog.title}</h2>
                            <div className="entry__meta">
                                <ul>
                                    <li>{blog.date}</li>
                                    <li><a href="#0" title="" rel="category tag">{blog.tags.map((tag, index) => (<span key={tag}><a href="#0">{tag}</a>{index < blog.tags.length - 1 && ', '}</span>))}</a></li>
                                    <li>{blog.author}</li>
                                </ul>
                            </div>
                        </header>
                        <div className="entry__content-media">
                            <img src={blog.image} alt={blog.title} />
                        </div>
                        <div className="entry__content">
                            <p className="lead">{blog.content}</p>
                        </div>
                        <p className="entry__tags">
                            <span>Tagged in </span>: {blog.tags.map((tag, index) => (<span key={tag}><a href="#0">{tag}</a>{index < blog.tags.length - 1 && ', '}</span>))}
                        </p>
                        <ul className="entry__post-nav h-group">
                            {/* Conditionally render Previous Article button */}
                            {prevPost && (
                                <li className="prev">
                                    <Link to={`/single/${prevPost.id}`} rel="prev">
                                        <strong className="h6">Previous Article</strong> {prevPost.title}
                                    </Link>
                                </li>
                            )}
                            {/* Conditionally render Next Article button */}
                            {nextPost && (
                                <li className="next">
                                    <Link to={`/single/${nextPost.id}`} rel="next">
                                        <strong className="h6">Next Article</strong> {nextPost.title}
                                    </Link>
                                </li>
                            )}
                        </ul>
                    </article>
                </div>
            </div>
        </div>
    );
};

export default BlogSingle;
