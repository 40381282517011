import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from './Header';
import Footer from './Footer';
import ChatWindow from './ChatWindow';
import ChatInput from './ChatInput';
import '../css/ChatPage.css'; // Ensure you have the correct path to the CSS file
import constants from '../chatConstants';
import ChatPageContent from './ChatPageContent';
import { v4 as uuidv4 } from 'uuid'; // Use this package to generate a new UUID

const ChatPage = () => {
    const [uuid, setUuid] = useState(null);
    const [messages, setMessages] = useState([]);

    // Fetch the UUID from local storage or create a new one if it doesn't exist
    useEffect(() => {
        let storedUuid = localStorage.getItem('uuid');
        if (!storedUuid || storedUuid === 'undefined' || storedUuid === 'null') {
            storedUuid = uuidv4(); // Generate a new UUID
            localStorage.setItem('uuid', storedUuid);
        }
        setUuid(storedUuid);

        // Fetch the conversation history using the UUID
        const fetchConversationHistory = async () => {
            try {
                const response = await axios.post(`${constants.baseURL}/fetchConversation`, {
                    uuid: storedUuid,
                });
                const fetchedMessages = response.data.messages.map(msg => ({
                    message: msg.content,
                    sender: msg.role === 'user' ? 'You' : 'Bot',
                }));
                setMessages(fetchedMessages);
            } catch (error) {
                console.error('Error fetching conversation history:', error);
            }
        };

        fetchConversationHistory();
    }, []);

    const addMessage = (message, sender) => {
        setMessages(prevMessages => [...prevMessages, { message, sender }]);
    };

    const sendMessage = async (message) => {
        addMessage(message, 'You');
        try {
            const response = await axios.post(`${constants.baseURL}/data`, {
                uuid: uuid,
                generateNewMessage: true,
                input: message,
            });

            console.log('Message generated:', response.data);
            addMessage(response.data.generation, 'Bot');
        } catch (error) {
            console.error('Error fetching response:', error);
            addMessage('Sorry, there was an error processing your request.', 'Bot');
        }
    };

    return (
        <div id="top">
            <div className="s-content">
                <ChatPageContent />
                <div className="chat-container">
                    <ChatWindow messages={messages} />
                    <ChatInput sendMessage={sendMessage} />
                </div>
            </div>
        </div>
    );
};

export default ChatPage;
