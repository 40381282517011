import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Header = () => {

  const location = useLocation();
  return (
    <header className="s-header">
      <div className="row">
        <div className="s-header__content column">
          <h1 className="s-header__logotext">
            <Link to="/" title="">Sihan Chen</Link>
          </h1>
          <p className="s-header__tagline">Code. Explore. Inspire.</p>
        </div>
      </div>
      <nav className="s-header__nav-wrap">
        <div className="row">
          <ul className="s-header__nav">
            {/* <li className={location.pathname === '/' ? 'current' : '#0'}><Link to="/">Home</Link></li> */}
            {/* <li className="has-children"><Link to="#0">Dropdown</Link>
              <ul>
                <li><Link to="#0">Submenu 01</Link></li>
                <li><Link to="#0">Submenu 02</Link></li>
                <li><Link to="#0">Submenu 03</Link></li>
              </ul>
            </li> */}
            {/* <li className={location.pathname === '/demo' ? 'current' : '#0'}><Link to="/demo">Demo</Link></li> */}
            {/* <li className={location.pathname === '/archives' ? 'current' : ''}><Link to="/archives">Archives</Link></li> */}
            {/* use has children for dropdown */}
            {/* <li className="current"><Link to="/blog">Blog</Link>
              <ul>
                <li><Link to="/blog">Blog Entries</Link></li>
                <li><Link to="/single">Single Blog</Link></li>
              </ul>
            </li> */}
            {/* <li className={`${location.pathname.includes('blog') ? 'current' : ''}`}>
              <Link to="/blog">Blog</Link>
            </li> */}

            <li className={location.pathname === '/about' ? 'current' : ''}>
              <Link to="/about">About Me</Link>
            </li>          </ul>
        </div>
      </nav>
      <a className="header-menu-toggle" href="#0" title="Menu"><span>Menu</span></a>
    </header>
  );
};

export default Header;
