import React, { useState } from 'react';
import axios from 'axios';
import constants from '../chatConstants';

const Login = ({ onLoginSuccess }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${constants.baseURL}/login`, {
        username,
        password,
      });
      console.log('Login response:', response.data);
      // Store the JWT token in local storage
      localStorage.setItem('token', response.data.token);
      
      // Call the onLoginSuccess function to update authentication state
      onLoginSuccess();
    } catch (err) {
      setError('Invalid credentials');
    }
  };

  return (
    <div id="top">
        <div className="s-content">
            <div className="row">
                <div>
                <h2>Admin Login</h2>
                <form onSubmit={handleLogin}>
                    <div>
                    <label>Username:</label>
                    <input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder="Enter username"
                        required
                    />
                    </div>
                    <div>
                    <label>Password:</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Enter password"
                        required
                    />
                    </div>
                    <button type="submit">Login</button>
                </form>
                {error && <p style={{ color: 'red' }}>{error}</p>}
                </div>
            </div>
        </div>
    </div>
  );
};

export default Login;
