import React from 'react';

const ChatPageContent = () => {
    return (
        <div>
            <div className="row add-bottom">
                <div className="column large-full">
                    <h1 className="h-remove-top">About me</h1>
                    <p className="lead">
                        Hello! I'm Sihan. I am a passionate 3rd-year ECE student. Here, you'll get to know more about my experiences, the projects I've worked on, and some of the insights I've gained along the way. Feel free to explore and connect with me!
                    </p>
                </div>
            </div>

            <div className="row">
                <div className="column large-6 tab-full">
                    <h3>About Me</h3>
                    <p>
                        <img width="150" height="150" className="h-pull-left" alt="profile-photo" src="/images/profile-photo.jpeg?v=1" />
                        I am currently pursuing a bachelor degree in Computer Engineering at the University of Toronto, with a focus on Software Development. I am also pursuing minors in Artificial Intelligence and Engineering Business. My journey has taken me through various exciting experiences, from developing personal website and app to learning and applying state-of-the-art large language models.
                    </p>
                    <p>
                        In my spare time, I also play with stock trading and keep an eye on the finance market. I enjoy exploring new skills and expose myself to technology that I have never touched on. This is because I believe that continuous learning and curiosity would be the keys to success in any field.
                    </p>
                </div>
                <div className="column large-6 tab-full">
                    <h3>Experiences</h3>
                    <ul className="disc">
                        <li>Web Developer - Personal website @sihan.ca, a portfolio website that showcases my projects and experiences</li>
                        <li>Research Intern with Professor Jonathan Rose at the University of Toronto -  Model Interpretability/Inference</li>
                        <li>Team member for University of Toronto Aerospace Team (UTAT) - ROS, Gazebo simulation</li>
                        <li>iOS Developer - School Life Guide, an information mobile app that outlines school policies</li>
                        <li>Internship at Inossem Inc. - Summer intern assistant to senior SAP programmers</li>
                    </ul>
                </div>
            </div>

            <div className="row">
                <div className="column large-full">
                    <h3>Explore My Posts</h3>
                    <ul>
                        <li><a href="/post/1">Deploying a React App on AWS Amplify</a></li>
                        <li><a href="/post/2">Overcoming Mixed Content Errors: A Guide for Web Developers</a></li>
                    </ul>
                </div>
            </div>

            <div className="row">
                <div className="column large-full">
                    <h3>Contact Me</h3>
                    <p>If you'd like to connect or learn more about my work, feel free to <a href="mailto:admin@sihan.ca">email me</a> or reach out on <a href="https://www.linkedin.com/in/sihan-chen-5743ab291/">LinkedIn</a>. I have also made a chat window if there is any immediate questions on my experience. Please feel free to give it a try!</p>
                </div>
            </div>
        </div>
    );
}

export default ChatPageContent;
