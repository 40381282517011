import React from 'react';

function Page1() {
  return (
    <div>
      <h1>Page 1</h1>
      <p>This is Page 1 content.</p>
    </div>
  );
}

export default Page1;
