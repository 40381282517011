
const constants = {
    // Whether this is the production branch or not
    localDevelopment: false,
    
    // Message displayed when there is a general error
    ErrMsg: 'Error',
    
    // Base URL depending on the environment
    baseURL: ''
};

// Assign baseURL based on localDevelopment flag
constants.baseURL = constants.localDevelopment ? "http://127.0.0.1:8000" : "https://api.sihan.ca";

export default constants;
